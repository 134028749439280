import React from "react";
import speedIcon from "../images/why_us/speed.svg";
import reliabilityIcon from "../images/why_us/reliability.svg";
import costIcon from "../images/why_us/cost_efficient.svg";
import qualityIcon from "../images/why_us/quality.svg";
import flexibilityIcon from "../images/why_us/flexibility.svg";

const WhyChooseUs = () => {
  return (
    <div id="whychooseus" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Why Choose Us
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            Discover why our clients choose us for their most critical projects.
          </h2>
        </div>
        <div className="px-12" data-aos="fade-up" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Speed */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-blue-500 hover:text-white rounded-full shadow-xl p-4 group">
              <div className="flex flex-col items-center justify-center">
                <img
                  alt="Speed"
                  className="w-16 h-16 group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={speedIcon}
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Speed
                </h2>
              </div>
            </div>

            {/* Reliability */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-blue-500 hover:text-white rounded-full shadow-xl p-4 group">
              <div className="flex flex-col items-center justify-center">
                <img
                  alt="Reliability"
                  className="w-16 h-16 group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={reliabilityIcon}
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Reliability
                </h2>
              </div>
            </div>

            {/* Cost Efficient */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-blue-500 hover:text-white rounded-full shadow-xl p-4 group">
              <div className="flex flex-col items-center justify-center">
                <img
                  alt="Cost Efficient"
                  className="w-16 h-16 group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={costIcon}
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Cost Efficient
                </h2>
              </div>
            </div>

            {/* Quality */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-blue-500 hover:text-white rounded-full shadow-xl p-4 group">
              <div className="flex flex-col items-center justify-center">
                <img
                  alt="Quality"
                  className="w-16 h-16 group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={qualityIcon}
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Quality
                </h2>
              </div>
            </div>

            {/* Flexibility */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-blue-500 hover:text-white rounded-full shadow-xl p-4 group">
              <div className="flex flex-col items-center justify-center">
                <img
                  alt="Flexibility"
                  className="w-16 h-16 group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={flexibilityIcon}
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Flexibility
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyChooseUs;
