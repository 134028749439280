import React from "react";
import img from "../images/web.svg";
import img2 from "../images/app.svg";
import img3 from "../images/hosting.svg";
import img5 from "../images/data_analytics.svg";
import img6 from "../images/market_survey.svg";
import img7 from "../images/ai_ml_solutions.svg";

const Services = () => {
  return (
    <div id="services" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            services
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            We are deeply committed to the growth and success of our clients.
          </h2>
        </div>
        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Web Development */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-[1rem] shadow-xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="Web Development"
                  className="w-20 h-20 mx-auto group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img}
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Web Development
                </h2>
                <p className="text-md font-medium">
                  We specialize in creating and optimizing high-quality, custom
                  websites that are mobile-friendly and easy-to-use for clients.
                </p>
              </div>
            </div>

            {/* Mobile App Development */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-[1rem] shadow-xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="Mobile App Development"
                  className="w-20 h-20 mx-auto group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img2}
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Mobile App Development
                </h2>
                <p className="text-md font-medium">
                  We develop custom cross-platform mobile apps that are robust
                  and optimized for performance and scalability.
                </p>
              </div>
            </div>

            {/* Market Survey */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-[1rem] shadow-xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="Market Survey"
                  className="w-20 h-20 mx-auto group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img6} // Replace with correct image
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Market Survey
                </h2>
                <p className="text-md font-medium">
                  Gain insights with our market survey services, providing
                  detailed analytics to understand market trends and customer
                  behavior.
                </p>
              </div>
            </div>

            {/* Data Analytics and Business Intelligence */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-[1rem] shadow-xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="Data Analytics and Business Intelligence"
                  className="w-20 h-20 mx-auto group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img5} // Replace with correct image
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Data Analytics & BI
                </h2>
                <p className="text-md font-medium">
                  Transform data into insights with our advanced data analytics
                  and business intelligence solutions.
                </p>
              </div>
            </div>

            {/* Advanced Engineering and AI/ML Solutions */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-[1rem] shadow-xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="AI/ML Solutions"
                  className="w-20 h-20 mx-auto group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img7} // Replace with correct image
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  AI/ML Solutions
                </h2>
                <p className="text-md font-medium">
                  Leverage advanced AI/ML technologies to develop cutting-edge
                  solutions tailored to your business needs.
                </p>
              </div>
            </div>

            {/* Software Maintenance and Support */}
            <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-[1rem] shadow-xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="Software Maintenance and Support"
                  className="w-20 h-20 mx-auto group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img3} // Replace with correct image
                />
                <h2 className="font-semibold my-4 text-xl text-center">
                  Software Maintenance
                </h2>
                <p className="text-md font-medium">
                  We provide comprehensive software maintenance and support to
                  keep your systems running smoothly and securely.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
